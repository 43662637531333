<script>
  import { conf, permissions, modal, lang } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import InputsGroup from './G_InputsGroup.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/activities/edit")
  }


  async function edit(id){
    setRoute(`/activities/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/activities/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/activities")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/activities/get/${id}`)
    obj={ ...obj }
  }

  isSame("listActivities","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listActivities", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/activities/list`, {
      activity: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/activities/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/activities")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/activities/tags/list')
  }

  let alpha="ABCDEFGHIJKLMNOPQRSTUVWXYZ"


  function getActivities(value, obj, field){
    return obj.activities?.length
  }
</script>


<CustomerBanner/>


<Route path="/activities/*" pathHide="/activities/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--activities" {objs} fields={[
      {id:'name', title:'g--Name'},
      {id:"activitys", title:"g--Activitys", format:getActivities},
    ]}/>
  </div>
</Route>


<Route path="/activities/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="activity" detail={obj.name} back="/activities"/>
      
      {#if obj.id}
        <div class="d-flex justify-content-end">
          <Button validity {update} class="col-auto"/>
        </div>
      {/if}
      <div class="fsScroll">
        <Form>
          <Input type="hr" icon="fa-info-square" label="General"/>
          <Input bind:value={obj.name} required type="text" icon label="Activity name"/>
          <Input bind:value={obj.type} set={"quiz"} inline type="radio" icon="fa-tags" label="Type" options={[ 
            { value: "quiz", label: "Quiz"},
            { value: "blind", label: "Blind test"},
            { value: "poll", label: "Poll"}
          ]}/>

          

          {#if obj.type=="quiz"}
            <Input type="hr" icon="fa-bowling-ball-pin" label="Questions"/>
            <InputsGroup let:i bind:values={obj.activities}>
             
              <Input label={`${i18n("question",$lang)} ${i+1}`} class="col-12" type="textarea" icon="fa-solid fa-circle-question" required bind:value={obj.activities[i].question} multilang translator/>
              <Input set="https://via.placeholder.com/150" class="col-lg-3" bind:value={obj.activities[i].questionMediaCdn} accept="image/png,image/jpeg,image/gif" legend maxSize=0.4 type="file" label="question media" icon="image"/>
              <Input label="help" class="col-lg-9" type="text" icon="info" bind:value={obj.activities[i].help} />
              
              
              <InputsGroup let:i={c} bind:values={obj.activities[i].choices}>
                <Input label={alpha[c]} class="col-lg-10" type="text" required bind:value={obj.activities[i].choices[c].label} multilang translator/>
                <Input class="col-lg-2" lg set={false} bind:value={obj.activities[i].choices[c].valid} type="switch" label=" " option="Correcte" help="question correct help"/>
              </InputsGroup>
            
              <Input set="https://via.placeholder.com/150" class="col-lg-3" bind:value={obj.activities[i].answerMediaCdn} accept="image/png,image/jpeg,image/gif" legend maxSize=0.4 type="file" label="answer media" icon="image"/>
              <Input label="explication" class="col-lg-9" type="textarea" icon="fa-badge-check" bind:value={obj.activities[i].answerHelp} multilang translator/>

            </InputsGroup>
          {/if}



          {#if obj.type=="blind"}
            <Input type="hr" icon="fa-photo-film-music" label="Medias"/>
            <InputsGroup let:i bind:values={obj.activities}>
            
              <Input label={`${i18n("media",$lang)} ${i+1}`} class="col-12" set="https://cdn.freesound.org/previews/320/320533_1015240-lq.mp3" bind:value={obj.activities[i].blindMediaCdn} accept="audio/*, video/*, image/png,image/jpeg,image/gif" legend maxSize=4 type="file" icon="fa-photo-film-music"/>
              <Input label="help" class="col-12" type="text" icon="info" bind:value={obj.activities[i].help} />
              <Input label="answer" class="col-12" type="text" icon="fa-badge-check" bind:value={obj.activities[i].answer} />

            </InputsGroup>
          {/if}

          

          {#if obj.type=="poll"}
            <Input type="hr" icon="fa-ballot-check" label="Choices"/>
            <InputsGroup let:i bind:values={obj.activities}>
              <Input label={`${i18n("choice",$lang)} ${i+1}`} class="col-12" type="text" icon="fa-check-to-slot" bind:value={obj.activities[i].choice} />
            </InputsGroup>
          {/if}

      
          <div class="row">
            <Button cond={obj.id} validity {del} class="col-auto"/>
            <Button cond={obj.id} validity {update} class="col-auto"/>
            <Button cond={!obj.id} validity {update} actionModel="save" class="col-auto"/>
          </div>
        </Form>
      </div>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>