<script>
  import { lang } from './stores.js'
  import { i18n, api } from './h.js'
  import Input from './G_Input.svelte'
  import I18n from './G_I18n.svelte'
  export let select=""
  export let obj={}
  if(!obj.licenceDetails) obj.licenceDetails={}
</script>

<!-- in agency customer licence screen -->
{#if select=="agencyCustomer"}

  <Input class="col-sm-12" bind:value={obj.upgrade} set={true} lg type="switch" icon="fa-triangle-exclamation" label="Customer can upgrade" option="oui"/>

  {#await api('/app/agency/licences') then licences}}
    <Input bind:value={obj.licence} set={"NONE"} inCol="col-md-4" type="radio" icon="fa-tags" label="Licence" options={[ 
      { value: "NONE", label: "NONE"},
      ...licences,
      { value: "CUSTOM", label: "CUSTOM"}
    ]}/>
  {/await}

  {#if obj.licence=="CUSTOM"}
    <Input bind:value={obj.licenceDetails.events} class="col-sm-6" set={100} min=1 max=1000 type="number" icon label="Number of events"/>
    <Input bind:value={obj.licenceDetails.users} class="col-sm-6" set={10} min=1 max=1000 type="number" icon label="Number of users"/>
  {/if}

{/if}



<!-- in account customer licence screen -->
{#if select=="accountCustomer"}

  {#if obj.licence!='NONE'}
    <hr>
    <div class="fw-bold"><I18n>licence--Type</I18n></div>
    {obj.plan?.name || obj.licence}
  {/if}

  <hr>
  <div class="fw-bold"><I18n>licence--Usage</I18n></div>
  <div><I18n id="Events"/> {obj.usages.events} / {obj.provision.events}</div>
  <div><I18n id="Users"/> {obj.usages.users} / {obj.provision.users}</div>

{/if}