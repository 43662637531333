<script>
  import { conf, permissions, modal, lang, wsc, route } from './stores.js'
  import { api, uuid, i18n, setRoute, matchRoute, dateTimeFormat, sleep, getById, asArray, isSame, anchor, closest } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import InputsGroup from './G_InputsGroup.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import Img from './G_Img.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import LineMenu from './G_LineMenu.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'


  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={valid:true}, tagsSelected=[], currentQuestion=0;

  $: list(keyword, filter, tagsSelected, sortBy)
  $: if($wsc && obj.id && matchRoute($route,'/events')) $wsc.unsubscribe(`manager_${obj.id}`)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.events) $modal={
      cancel:true,
      title:i18n("licence--No more events"), 
      msg:i18n("licence--No more events msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/events/manage//design")
  }


  async function edit(id){
    setRoute(`/events/manage/${id}/dashboard`)
  }

  async function update(){
    obj = await api(`/app/customers/events/set`,{obj: obj})
    list()
    getTags()
    setRoute(`/events/manage/${obj.id}/dashboard`)
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/events/get/${id}`)
    if(obj.id) $wsc.subscribe(`manager_${id}`)
    if(obj.id) $wsc.send('state',{eventId:obj.id, type:"manager"})
    obj={ ...{
      style:{},
      program:[]
    }, ...obj }
  }

  isSame("listEvents","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listEvents", checkArgs)) return
    let req={ ...filter, ...(tagsSelected.length?{tags: { $in: tagsSelected}}:{})}
    objs = await api(`/app/customers/events/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(id){
    if(id) obj=getById(objs, id) //use in list action
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.title)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/events/delete/${obj.id}`)
      list()
      setRoute("/events")
    }}
  }

  async function clone(obj){
    $modal={cancel:true, title:`${i18n("events--Duplicate")} ${i18n(obj.title)}`, color:'warning', confirm:async ()=>{
      let data=await api(`/app/customers/events/clone/${obj.id}`)
      list()
      getTags()
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/events/tags/list')
  }

  let activities=[]
  getActivities()
  async function getActivities(){
    activities = await api(`/app/customers/activities/list`)
  }

	function seeYourTarget(obj) {
    let url
    if(obj.useDomain && obj.fqdn) url=obj.fqdn
    else url=$conf.customerFqdn + "/wall/" +obj.shortId
		window.open(`https://${url}`,"Event", "width=1600,height=900,resizable,scrollbars=yes,status=0");
	}

  //others
  let dash={}
  async function getDash(){
    dash = await api('/event/dashboard',{eventId:obj.id})
    if(dash) dash.ok=true
    else dash={}
    return dash
  }

  let cmdParams={}
  function cmd(evt) {
    let cmd = closest(evt,'cmd')
    cmdParams.activityId= closest(evt,'param')
    $wsc.send('cmd',{eventId:obj.id, cmd:cmd, params:cmdParams})
  }

  function sound(evt) {
    let cmd = closest(evt,'cmd')
    $wsc.send('doSound',{eventId:obj.id, cmd:cmd})
  }
  

  if($wsc) $wsc.onEvent.state=(p)=>{state(p)}
  let players=[],view, eventActivities=[], activity={}, lottery={}
  async function state(p){
    players=p.players || []
    if(p.event) {
      view=p.event.view
      eventActivities=p.activities || []
      activity=p.activity || {}
    }
  }

  let activityIcons={
    quiz:'list-check',
    blind:'photo-film-music',
    poll:'square-poll-vertical'
  }
</script>


<CustomerBanner/>


<Route path="/events" pathHide="/events/manage/*" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}
      bind:filter filters={[
        { filter: {valid:true}, title:'g--valid' },
        { filter: {valid:false}, title:'g--not valid' }
      ]}
  
      bind:sortBy sortBys={[
        { id:'title', title:'g--By name' }
      ]}
    />

    <List type="card"  cardShadow cardUp refresh={()=>list()} title="events" {objs}>
      <div slot="header" class="w-100 d-flex justify-content-between" let:obj>
        <div>{i18n(obj.name, $lang)}</div>
      </div>
      <div slot="body" let:obj>
        <div class="row m-3">
          <div class="col-6 text-center pe-0">
            <Img fluid maxh=100 src={obj.logo}/>
          </div> 
          <div class="col-2"></div>
          <div class="col-4 small text-nowrap">
            <div  on:click={()=>edit(obj.id)} 
              class="my-2 pointer text-success"><I me cube/><I18n>Manage</I18n>
            </div>
            <div class="dropdown">
              <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <I me cog/><I18n>Action</I18n>
              </div>
              <ul class="dropdown-menu">
                <li><div class="pointer dropdown-item" on:click={()=>clone(obj)}><I me copy/><I18n>Duplicate</I18n></div></li>                            
                <li><div class="pointer dropdown-item text-danger" on:click={()=>del(obj)}><I me trash/><I18n>Delete</I18n></div></li>
              </ul>
            </div>
          </div>
        </div>
        <div>{#each asArray(obj.tags) as tag}<span class="badge bg-secondary me-1">{tag}</span>{/each}</div>
      </div>
      <div slot="footer" let:obj>
        <div class="pointer" on:click={()=>seeYourTarget(obj)}>{`https://${$conf.customerFqdn}/wall/${obj.shortId}`}</div>
      </div>

    </List>
  </div>
</Route>





<Route path="/events/manage/:id?/*" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="event" detail={obj.name} back="/events"/>

      <LineMenu noAutoselect baseRoute={`/events/${routeParams.id}/manage`} valid
        menus={[
          {route: `/events/manage/${routeParams.id}/dashboard`, name: 'dashboard', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/design`, name: 'design', noReload:true, valid: true},
          {route: `/events/manage/${routeParams.id}/program`, name: 'program', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/progress`, name: 'progress', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/players`, name: 'players', noReload:true, valid: obj.id},
        ]}
      />

      <!-- DASHBOARD -->
      <Route path="/events/manage/:id/dashboard" let:routeParams fsContent>
        <Title title="Dashboard"/>
        {#await getDash() then dash}

          <div class="fsScroll">
            <div class="row row-cols-1 row-cols-md-4 g-4">
              {#each [
                {route: `/events/manage/${routeParams.id}/progress`, name: 'Players', icon:'people-group', value: dash.players || 0, color:"success", valid:true},         
                {route: `/events/manage/${routeParams.id}/program`, name: 'Periods', icon:'list', value: dash.periods || 0, color:"success", valid:true},         
              ] as d}
                {#if d.valid}
                  <div class="col" on:click={()=>setRoute(d.route)}>
                    <div class="card h-100 pointer shadow">
                      <div class="card-body">
                        <div class="card-dash">

                          {#if d.type=="right"}
                            <div>
                              <div class="text-{d.color}">{d.value}</div>
                              <div class="text">{i18n(d.name, $lang)}</div>
                            </div>
                            <div class="text-{d.color}"><I icon={d.icon}/></div>
                          {:else}
                            <div class="text-{d.color}"><I icon={d.icon}/></div>
                            <div class="text-end">
                              <div class="text-{d.color}">{d.value}</div>
                              <div class="text">{i18n(d.name, $lang)}</div>
                            </div>
                          {/if}
                        </div>
                      </div>
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          </div>
        {/await}
      </Route>


      <Route path="/events/manage/:id?/design" let:routeParams fsContent>

        <div class="anchor" on:click={anchor}>
          <span><I18n>General</I18n></span> |
          <span><I18n>Activities</I18n></span>
        </div>

        <Form fsContent norow>
          {#if obj.id}
            <div class="d-flex justify-content-end">
              <Button validity {update} class="col-auto"/>
            </div>
          {/if}
          <div class="row fsScroll">
            <Input type="hr" label="General" icon="info"/>
            <Input class="col-lg-8" bind:value={obj.name} icon="fa-bullhorn" type="text" label="g--Name" multilang translator required/>
            <Input class="col-lg-4" lg set={true} bind:value={obj.valid} type="switch" label=" " option="g--valid" help="g--users can connect"/>
            <Input class="col-lg-12" bind:value={obj.slogan} icon type="text" label="g--Slogan" multilang translator/>
            <Input class="col-lg-12" bind:value={obj.logo} maxSize=0.4 accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="g--Logo" icon/>
            
            
            <!-- Colors -->
            <Input class="col-12" type="hr" icon="fa-palette" label="g--Colors"/>
  
            <Input class="col-lg-4" set="#ec0909" bind:value={obj.style.brandColor} icon type="color" colorText label="g--brandColor"/>
            <Input class="col-lg-4" set="#000000" bind:value={obj.style.brandColor2} icon type="color" colorText label="g--brandColor2"/>
            <Input class="col-lg-4" set="#FFFFFF" bind:value={obj.style.colorSlogan} icon type="color" colorText label="g--color slogan"/>
            
            <Input class="col-lg-4" set="#ffffff" bind:value={obj.style.navbarBgColor} icon type="color" colorText label="g--navbarBgColor"/>
            <Input class="col-lg-4" set="#17baa5" bind:value={obj.style.buttonBgColor} icon type="color" colorText label="g--color bg button"/>
            <Input class="col-lg-4" set="#FFFFFF" bind:value={obj.style.buttonTextColor} icon type="color" colorText label="g--color text button"/>
            
            <Input class="col-lg-4" bind:value={obj.colorBg} set="#ffffff" icon type="color" label="g--color bg" colorText/>
            <Input lg class="col-lg-8" set={false} bind:value={obj.isImageBg} label=" " type="switch" option="g--is img bg" colorText/>
            {#if obj.isImageBg}
              <Input class="col-lg-4" bind:value={obj.imageBgCdn} icon="image" type="file" label="g--image bg" accept="image/svg,image/png,image/jpeg,image/gif"/>
              <Input set=0 class="col-lg-4" bind:value={obj.transparencyBg} type="range" showValue unit="%" label="g--transparency bg" min=0 max=100 step=1/>
            {/if}
            
            <Input type="hr" label="Activities" icon="fa-gamepad"/>
            <Input set={[]} class="col-lg-12" inline bind:value={obj.activities} options={activities} icon="fa-bullseye" type="switch" label="g--activity"/>
            
            <Input lg class="col-lg-12" set={true} bind:value={obj.useGravatar} label=" " type="switch" option="g--use gravatar" colorText/>
            <Input lg class="col-lg-12" set={true} bind:value={obj.useOrganization} label=" " type="switch" option="g--use organization" colorText/>
            
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </Form>
      </Route>



      <!-- PROGRAM -->
      <Route path="/events/manage/:id/program" let:routeParams fsContent>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="fsScroll">
          <Form>
            <Input type="hr" label="events--Program" icon="fa-calendar-range"/>
            <InputsGroup let:i bind:values={obj.program}>
              <Input set={uuid()} type="hidden" bind:value={obj.program[i].id} />
              <Input label="event--slot name" class="col-lg-10" type="text" icon="fa-calendar-day" bind:value={obj.program[i].name} multilang translator/>
              <Input label=" " lg set={true} class="col-lg-2" bind:value={obj.program[i].valid} type="switch" option="active"/>
              <Input label="description" class="col-lg-12" type="textarea" icon="info" bind:value={obj.program[i].description} multilang translator/>
              <Input type="empty"/>
              <Input label=" " lg set={true} class="col-lg-2" bind:value={obj.program[i].isStep} type="switch" option="events--isStep"/>


              <Input set={dateTimeFormat(new Date(), 'time')} class="col-lg-3" bind:value={obj.program[i].startTime} icon type="time" label="events--From"/>
              {#if !obj.program[i].isStep}
                <Input set={dateTimeFormat(new Date(), 'time')} class="col-lg-3" bind:value={obj.program[i].endTime} icon type="time" label="events--To"/>
              {/if}
            </InputsGroup>

            <div class="row">
              {#if obj.id}
                <Button validity {update} class="col-auto"/>
              {:else}
                <Button validity {update} actionModel="save" class="col-auto"/>
              {/if}
            </div>
          </Form>
        </div>
      </Route>


      <!-- PROGRESS -->
      <Route path="/events/manage/:id/progress" let:routeParams fsContent>
        <div class="fsScroll">
          <button cmd="welcome" on:click={cmd} class="btn btn-{matchRoute("/"+view, '/welcome/*')?'success':'outline-success'}" ><I18n>Welcome</I18n></button>
          <button cmd="program" on:click={cmd} class="btn btn-{matchRoute("/"+view, '/program/*')?'success':'outline-success'}"><I18n>Program</I18n></button>
          <button cmd="game" on:click={cmd} class="btn btn-{matchRoute("/"+view, '/game/*')?'success':'outline-success'}"><I18n>Game</I18n></button>
          <button cmd="photos" on:click={cmd} class="btn btn-{matchRoute("/"+view, '/photos/*')?'success':'outline-success'}"><I18n>Photos</I18n></button>
          <button cmd="scores" on:click={cmd} class="btn btn-{matchRoute("/"+view, '/scores/*')?'success':'outline-success'}"><I18n>Scores</I18n></button>

          <button cmd="test" on:click={sound} class="btn btn-outline-dark"><I18n>Wall sound test</I18n></button>
          <button cmd="wait" on:click={sound} class="btn btn-outline-dark"><I me solid repeat/><I18n>Wait music</I18n></button>
          <button cmd="game" on:click={sound} class="btn btn-outline-dark"><I18n>Game time</I18n></button>
          <button cmd="fanfare" on:click={sound} class="btn btn-outline-dark"><I18n>Fanfare</I18n></button>
          <button cmd="intro" on:click={sound} class="btn btn-outline-dark"><I18n>Intro</I18n></button>
          <button cmd="playstop" on:click={sound} class="btn btn-outline-dark"><I solid play/>/<I solid pause/></button>
          <hr>
          
          {#if view=="game"}
            {#each eventActivities as activity}
              <button cmd="game/{activity.type}" param={activity.id} on:click={cmd} class="btn btn-success"><I icon={activityIcons[activity.type]} me solid/>{activity.name}</button>
            {/each}
            <button cmd="game/lottery" on:click={cmd} class="btn btn-success"><I gift me solid/><I18n>Lottery</I18n></button>
            <button cmd="game/buzzer" on:click={cmd} class="btn btn-success"><I circle-dot me solid/><I18n>Buzzer</I18n></button>

          {/if}

          <Form>
            {#if view=="game/lottery"}
              <div><I18n>Lottery</I18n></div>
              <Input class="col-lg-4" lg set={true} bind:value={lottery.excludeWinner} type="switch" label=" " option="exclude winners"/>
              <Input class="col-lg-4" lg set={0} bind:value={lottery.selectScoreGreaterOrEqual} type="number" label="select score greater or equal"/>
              <button on:click={()=>{$wsc.send('doLottery',{eventId:obj.id, options:lottery})}} class="btn btn-success"><I18n>Start</I18n></button>
            {/if}
          </Form>
          {#if view=="game/quiz"}
            <div class="fs-2 fw-bold">{i18n(activity.name)}</div>
  
            {#if activity}
              {#each asArray(activity.activities) as act,index}
                <div on:click={()=>{currentQuestion=index}} class:currentQuestion={currentQuestion==index}>
                <div>{index+1} - {i18n(act.question)}</div>
                <button on:click={()=>{$wsc.send('doQuiz',{eventId:obj.id, quizId:activity.id, quizIndex:index, quizStep:"question"})}} class="btn btn-sm btn-outline-success"><I18n>Question</I18n></button>
                <button on:click={()=>{$wsc.send('doQuiz',{eventId:obj.id, quizId:activity.id, quizIndex:index, quizStep:"choices"})}} class="btn btn-sm btn-outline-success"><I18n>Choix</I18n></button>
                <button on:click={()=>{$wsc.send('doQuiz',{eventId:obj.id, quizId:activity.id, quizIndex:index, quizStep:"answer"})}} class="btn btn-sm btn-outline-success"><I18n>Réponse</I18n></button>
                <button on:click={()=>{$wsc.send('doQuiz',{eventId:obj.id, quizId:activity.id, quizIndex:index, quizStep:"winner"})}} class="btn btn-sm btn-outline-success"><I18n>Le plus rapide</I18n></button>
                </div>
                <hr>
              {/each}
            {/if}

          {/if}
          {#if view=="game/blind"}
            <div><I18n>Blind test</I18n></div>
            <button on:click={()=>{$wsc.send('doBlind',{eventId:obj.id})}} class="btn btn-success"><I18n>Start</I18n></button>
          {/if}
          {#if view=="game/buzzer"}
            <div><I18n>Buzzer</I18n></div>
            <button on:click={()=>{$wsc.send('doBuzzer',{eventId:obj.id})}} class="btn btn-success"><I18n>Start</I18n></button>
          {/if}
        </div>
      </Route>




      <!-- PLAYERS -->
      <Route path="/events/manage/:id/players" let:routeParams fsContent>
        <div class="fsScroll">
          <button on:click={()=>{$wsc.send('reset',{eventId:obj.id, resetScores:true})}} class="btn btn-sm btn-danger"><I18n>Clear scores</I18n></button>
          <button on:click={()=>{$wsc.send('reset',{eventId:obj.id, resetLottery:true})}} class="btn btn-sm btn-danger"><I18n>Clear lottery winners</I18n></button>
          <button on:click={()=>{$wsc.send('reset',{eventId:obj.id, resetPlayers:true})}} class="btn btn-sm btn-danger"><I18n>Delete players</I18n></button>
          <hr>
          {players.length} <I18n>Players</I18n>
          {#each players as player,i}
            <div>
              <Img class="m-1" src={obj.useGravatar?`${$conf.apiUrl}/avatar/${player.id}`:obj.logo} w=20/>
              {player.username} / {player.organization || ''}
              <b>{player.score || 0}</b>
              {#if player.lottery}<I badge-check solid/>{/if}
            </div>
          {/each}
        </div>
      </Route>
      



    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>



<style>

  .card-dash {
    line-height:2.5rem;
    font-size:3rem;
    display: flex;
    justify-content: space-between;
  }
  .text{
    line-height:1rem;
    font-size:1rem;
  }

  .currentQuestion{
    background-color: #ff2bb933;
  }
</style>